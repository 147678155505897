import { TFunction } from 'i18next';

export const getFooterLinks = (t: TFunction) => [
  {
    sm: 6,
    md: 2,
    lg: 2,
    headerText: t('footer.work-visas.headerText'),
    headerLink: '/visas',
    links: [
      { text: t('footer.work-visas.links.0'), link: '/visas/E2' },
      { text: t('footer.work-visas.links.1'), link: '/visas/H1' },
      { text: t('footer.work-visas.links.2'), link: '/visas/L1' },
      { text: t('footer.work-visas.links.3'), link: '/visas/O1' },
      { text: t('footer.work-visas.links.4'), link: '/visas/P1' },
    ],
  },
  {
    sm: 6,
    md: 2,
    lg: 2,
    headerText: t('footer.green-cards.headerText'),
    headerLink: '/green-cards',
    links: [
      { text: t('footer.green-cards.links.0'), link: '/green-cards/family-based' },
      { text: t('footer.green-cards.links.1'), link: '/green-cards/employment-based' },
      { text: t('footer.green-cards.links.2'), link: '/green-cards/investment-based' },
    ],
  },
  {
    sm: 6,
    md: 3,
    lg: 2,
    headerText: t('footer.our-services.headerText'),
    links: [
      { text: t('footer.our-services.links.0'), link: '/visa-finder' },
      { text: t('footer.our-services.links.1'), link: '/solutions' },
      { text: t('footer.our-services.links.2'), link: '/why-visalex' },
      { text: t('footer.our-services.links.3'), link: '/platform' },
      { text: t('footer.our-services.links.4'), link: '/blog' },
    ],
  },
  {
    sm: 6,
    md: 3,
    lg: 2,
    headerText: t('footer.get-in-touch.headerText'),
    links: [
      { text: t('footer.get-in-touch.links.0'), link: '/contact-us' },
      { text: t('footer.get-in-touch.links.1'), link: '/help' },
      { text: '+1 (844) 847-2539', link: 'tel:+18448472539', external: true },
    ],
  },
];

export const getTermsAndPrivacyLinks = (t: TFunction) => [
  { text: t('footer.terms-and-privacy.links.0'), link: '/terms' },
  { text: t('footer.terms-and-privacy.links.1'), link: '/privacy-policy' },
];
